.mainFrame {
    background: linear-gradient(131deg, #E84FE2 0%, #8571FF 100%);
}

.framImg {
    max-width: 95%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.tabText {
    padding-block: 5px;
    border-bottom: 4px solid transparent;
    color: white;
    text-transform: capitalize;
}

.active {
    border-bottom: 4px solid white;
}

.tabs {
    background: linear-gradient(131deg, #D656E7 0%, #8571FF 100%);
    padding-top: 15px;
    width: 100% !important;
    margin-top: -25px;
}