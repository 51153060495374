.cancelButton {
  background: white !important;
  color: black !important;
  cursor: pointer;
  box-shadow: 0px 1px 5px rgb(212, 205, 205);
  transition: transform 150ms linear;
  &:hover {
    transform: scale(1.15);
    box-shadow: 0px 2px 5px rgb(189, 183, 183);
  }
}

.headingFont {
  font-size: 30px;
}

.bannerLine1 {
  font-size: 16px;
}

.textFont {
  font-size: 14px;
}

.bannerLine5 {
  font-size: 16px;
  font-style: italic;
}

.backgroundColor{
    background-color: var(--qrcode-background) !important;
}

.qrCode {
    max-width: 80%;
    width: auto;
    height: 80%;
}