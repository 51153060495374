@import "@/styles/utils.scss";

.categoryBar {
  height: 100%;
  width: 100%;
  align-items: center;
  display: flex;
}

.scrollContainer {
  display: flex;
  height: 100%;
  align-items: center;
  width: 100%;
  overflow-x: auto;
}

.scrollContainer::-webkit-scrollbar {
  display: none;
  overflow: scroll;
}

.scrollDiv {
  display: flex;
  height: 100%;
  align-items: center;
  column-gap: 8px;
  font-size: 14px;
}

.scrollDiv > a:first-of-type {
  margin-left: -8px;
}

.categoryBtn {
  position: relative;
  cursor: pointer;
  padding: 5px;
  border-radius: 25px;

  &:not(.selected):hover {
    background-color: var(--category-item-bg-color-hover);
  }
}

.arrowIcon {
  border: none !important;
  cursor: pointer !important;
  transition: transform 0.3s;
  width: 33px;
  height: 33px;

  @include mq(L) {
    box-shadow: 0px 1px 5px rgb(212, 205, 205);
  }
}

.arrowIcon:hover {
  background-color: inherit !important;
  transform: scale(1.1);

  @include mq(L) {
    box-shadow: 0px 1px 5px rgb(208, 200, 200) !important;
    transform: scale(1.2);
  }
}

.selected {
  font-weight: bold;
  background-color: var(--category-item-bg-color);
  color: var(--category-item-font-color);
}

.categoryBarContainer {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: sticky;
  top: calc(var(--main-header-height) + 2px);
  background-color: var(--category-color);
  z-index: 10;

  @include mq(L) {
    height: 160px;
  }
}

.innerCategory {
  height: 100%;
  width: 95%;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.headingText {
  font-size: 32px;

  @include mq(L) {
    font-size: 36px;
  }
}

.toolTip {
  text-align: center;
}
