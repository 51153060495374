@import "@/styles/utils.scss";

.appBar {
   background-color: var(--main-header-bg-color);
   z-index: 10;
}

.headerButton {
   background: rgb(82, 88, 228) !important;
   color: white !important;
   border: none !important;
   cursor: pointer !important;
}

.get_the_app {
   display: none !important;

   @include mq(S) {
      display: none;
   }
}
.get_the_apps {
   display: block;

   @include mq(S) {
      display: none;
   }
}