$breakpoints: (
    S: 640px,
    M: 768px,
    L: 1024px,
    XL: 1280px,
    XXL: 1536px
);

@mixin mq($size: S) {
    $width: map-get($breakpoints, $size);

    @media only screen and (min-width: $width) {
        @content;
    }
}

@mixin hidden() {
    display: none;
}

@mixin invisible($visible: hidden) {
    visibility: $visible;
}

@mixin center-inline() {
    display: flex;
    justify-content: center;
    @content;
}

@mixin center-block() {
    display: flex;
    align-items: center;
    @content;
}

@mixin center() {
    display: flex;
    justify-content: center;
    align-items: center;
    @content;
}

@mixin container($width: 95%) {
    width: $width;
    margin-inline: auto;
}

.container {
    width: 95%;
    margin-inline: auto;
}

.searchContainer{
    width:97%;
    margin-inline: auto;
}

.skeleton {
    animation: skeleton-loading 1s linear infinite normal;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    position: relative;
}

.cursor-pointer {
    cursor: pointer !important;
}

@keyframes skeleton-loading {
    0% {
        background-color: white;
    }

    100% {
        background-color: gray;
    }
}